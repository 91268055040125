import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'
import SEO from '../components/seo'
import NewsSectionBlock from '../components/NewsSectionBlock'
import Link from '../components/Common/Link'
import Typography from '../components/Common/Typography'
import List from '../components/Common/List'

import * as s from '../pages/news.module.scss'

const BimConstructionProjectManagement: React.FC = () => {
  return (
    <>
      <SEO
        title="BIM for Construction Project Management"
        description="BIM has become a ubiquitous tool in the construction industry, not only for digital construction but also for project management. As BIM continues to evolve, it is essential to understand its role in project management throughout the project life cycle."
      />
      <NewsSectionBlock date="10.10.2023">
        <Typography variant="h1" color="blue">
          BIM technology for construction project management
        </Typography>
        <div className="flex flex-col">
          <div className="flex items-center">
            <Typography variant="body2" className="mr-2">
              Nikita Pospelov
            </Typography>
            <Link
              href="https://www.linkedin.com/in/nikita-pospelov-bim"
              target="_blank"
              rel="nofollow noreferrer"
              className="flex has-text-primary"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 3.30667C0 2.53445 0.270278 1.89739 0.810811 1.39547C1.35134 0.893536 2.05406 0.642578 2.91892 0.642578C3.76835 0.642578 4.45559 0.889667 4.98069 1.38389C5.52123 1.89354 5.79151 2.55762 5.79151 3.37617C5.79151 4.11748 5.52897 4.73523 5.00386 5.22945C4.46333 5.7391 3.7529 5.99393 2.87259 5.99393H2.84942C1.99999 5.99393 1.31275 5.7391 0.787645 5.22945C0.262541 4.7198 0 4.07886 0 3.30667ZM0.301158 23.5769V8.10204H5.44402V23.5769H0.301158ZM8.29344 23.5769H13.4363V14.936C13.4363 14.3955 13.4981 13.9785 13.6216 13.685C13.8378 13.1599 14.166 12.7159 14.6062 12.353C15.0463 11.9901 15.5984 11.8086 16.2625 11.8086C17.9923 11.8086 18.8571 12.9746 18.8571 15.3067V23.5769H24V14.7044C24 12.4186 23.4595 10.685 22.3784 9.50358C21.2973 8.32211 19.8687 7.73138 18.0927 7.73138C16.1004 7.73138 14.5483 8.58852 13.4363 10.3028V10.3491H13.4131L13.4363 10.3028V8.10204H8.29344C8.32432 8.59624 8.33977 10.1329 8.33977 12.7121C8.33977 15.2912 8.32432 18.9128 8.29344 23.5769Z"
                  fill="#102538"
                />
              </svg>
            </Link>
          </div>
          <Typography variant="body2">
            Head of BIM Department at SIGNAX
          </Typography>
        </div>
        <Typography variant="body1">
          Building Information Modeling (BIM) has become a ubiquitous tool in
          the construction industry, not only for digital construction but also
          for project management. As BIM continues to evolve, it is essential to
          understand its role in project management throughout the project life
          cycle. This article aims to provide a comprehensive review of the
          integration of BIM and project management in construction projects.
          While there has been a significant increase in research on this topic
          over the past decade, the relationship between BIM and project
          management could be more robust. To address this gap, I propose a BIM
          capabilities framework that summarizes BIM's potential for project
          management in the project life cycle. This framework will help
          construction project teams, and stakeholders integrate BIM with
          project management more effectively, ultimately improving project
          management efficiency in construction projects.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/bim-construction-project-management/image-1.png"
            alt="The Future is here"
            title="The Future is here"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Fig.1 The Future is here
          </Typography>
        </div>
        <Typography variant="h2" color="blue">
          Project management Knowledge areas and Process groups*
        </Typography>
        <Typography variant="body1">
          We typically categorize the project management process into these
          knowledge areas when discussing it:
        </Typography>
        <ol className={cn(s.textLists, s.decimal)}>
          <li>Scope</li>
          <li>Schedule</li>
          <li>Cost</li>
          <li>Quality</li>
          <li>Resource</li>
          <li>Communication</li>
          <li>Risk</li>
          <li>Procurement</li>
        </ol>
        <Typography variant="body1">
          The project management process comprises the following process groups:
        </Typography>
        <ol className={cn(s.textLists, s.decimal)}>
          <li>Initiating and Planning</li>
          <li>Executing</li>
          <li>Monitoring and controlling</li>
          <li>Closing and operating</li>
        </ol>
        <Typography variant="body1">
          *In the last iteration of PMBOK® guide 7th edition, the Project
          Management Institute changed the structure of processes and knowledge
          areas. Nevertheless, the core principles of waterfall methodology for
          construction projects remain the same.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/bim-construction-project-management/image-2.png"
            alt="Aerial photogrammetry results"
            title="Aerial photogrammetry results"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Fig.2 Aerial photogrammetry results
          </Typography>
        </div>
        <Typography variant="h2" color="blue" className="mb-0">
          Use cases
        </Typography>
        <Typography variant="body1">
          Let me outline the most demanded and valuable BIM use cases relevant
          to various construction project roles.
        </Typography>
        <Typography variant="h3">Initiating and Planning</Typography>
        <Typography variant="body1">Property Developer role</Typography>
        <List className="pl-4">
          <li>Site analysis</li>
          <li>Concept design review and approval</li>
          <li>Different design variants just in minutes</li>
          <li>Fast cost analysis for each design variant</li>
          <li>VR and AR technology for better visual understanding</li>
        </List>
        <Typography variant="body1">Lead Consultant role</Typography>
        <List className="pl-4">
          <li>
            The efficient design process with a small price of a project design
            change
          </li>
          <li>
            Simultaneous multidisciplinary design process and engineering
            analysis
          </li>
          <li>
            Online collaboration with various stakeholders using RFI and Issues
            connected to the BIM model
          </li>
          <li>Identify potential design flaws</li>
          <li>Clash free detailed BIM model and design drawings</li>
          <li>WBS generation for phase planning</li>
        </List>
        <Typography variant="body1">General Contractor role</Typography>
        <List className="pl-4">
          <li>
            A convenient way to analyze a project in 3D during the bidding stage
          </li>
          <li>BOQ export based on multiple rules and filters</li>
          <li>
            Resource planning with WBS codes integrated with a 4D BIM model
          </li>
          <li>Create accurate estimates and schedules</li>
          <li>
            Collaborate with developers and consultants to resolve issues
            quickly
          </li>
          <li>
            Plan QA/QC procedures with checklists connected to the BIM model
          </li>
        </List>
        <Typography variant="h3">Executing</Typography>
        <Typography variant="body1">Property Developer role</Typography>
        <List className="pl-4">
          <li>Cost and schedule change management</li>
          <li>Design change management</li>
        </List>
        <Typography variant="body1">Lead Consultant role</Typography>
        <List className="pl-4">
          <li>Cost and schedule change implementation</li>
          <li>Scope tracking</li>
          <li>Baseline/actual BIM model comparison</li>
          <li>Issue management in the cloud</li>
          <li>Communication management</li>
        </List>
        <Typography variant="body1">General Contractor role</Typography>
        <List className="pl-4">
          <li>
            The procurement process, including BIM quantity takeoffs and
            estimates
          </li>
          <li>Daily work progress planning between subcontractor teams</li>
          <li>Construction supervision</li>
          <li>Digital fabrication (CNC and 3D printing)</li>
        </List>
        <Typography variant="h3">Monitoring and controlling</Typography>
        <Typography variant="body1">Property Developer role</Typography>
        <List className="pl-4">
          <li>Cost and schedule change control</li>
          <li>Mobile applications for real-time BIM data</li>
        </List>
        <Typography variant="body1">Lead Consultant role</Typography>
        <List className="pl-4">
          <li>QA/QC monitoring and controlling</li>
          <li>Online analytics reports and forecasts</li>
        </List>
        <Typography variant="body1">General Contractor role</Typography>
        <List className="pl-4">
          <li>Quality control and issue management</li>
          <li>Tracking and marking implemented scope</li>
          <li>Dashboard reports with BIM progress data</li>
          <li>Laser scanning and point clouds comparison</li>
        </List>
        <Typography variant="h3">Closing and operating</Typography>
        <Typography variant="body1">Property Developer role</Typography>
        <List className="pl-4">
          <li>Facility operations planning and implementing</li>
          <li>Facility management documentation</li>
          <li>Security management</li>
        </List>
        <Typography variant="body1">Lead Consultant role</Typography>
        <List className="pl-4">
          <li>Digital twin development</li>
        </List>
        <Typography variant="body1">General Contractor role</Typography>
        <List className="pl-4">
          <li>As-built BIM model development</li>
        </List>
        <Typography variant="body1">
          As we see, project stakeholders can utilize various BIM use cases at
          any stage of a construction project. The validity of the
          implementation or adoption of each process should always be calculated
          and justified using the ROI ratio.
        </Typography>
        <div className={s.imageWrap}>
          <div className="flex">
            <StaticImage
              width={550}
              height={318}
              src="../assets/images/solutions/bim-construction-project-management/image-3.png"
              alt="Construction BIM model"
              title="Construction BIM model"
              placeholder="blurred"
            />
            <StaticImage
              width={442}
              height={318}
              src="../assets/images/solutions/bim-construction-project-management/image-4.png"
              alt="Construction BIM model"
              title="Construction BIM model"
              placeholder="blurred"
            />
          </div>
          <Typography variant="body2" color="gray">
            Fig. 3 Construction BIM model
          </Typography>
        </div>
        <Typography variant="h2" color="blue">
          Developer's Perspective
        </Typography>
        <Typography variant="body1">
          For developers, BIM technology offers a comprehensive view of a
          project, empowering them to make informed decisions from the earliest
          stages. By utilizing BIM during the planning and design phases,
          developers can visualize the final product in a virtual environment,
          allowing for better communication with architects, engineers, and
          various consultants. The technology facilitates the exploration of
          various design options, enabling developers to assess the feasibility,
          cost implications, and potential risks associated with different
          alternatives. BIM also plays a vital role in identifying and resolving
          clashes or conflicts between various building systems before
          construction commences, saving time and money in the long run.
        </Typography>
        <Typography variant="body1">
          Moreover, BIM technology enhances collaboration between developers and
          stakeholders throughout the project lifecycle. By providing a
          centralized digital repository (cloud-based CDE software like Autodesk
          Construction Cloud, Procore, Aconex, Asite, Trimble Connect, Bentley
          ProjectWise, and others), it allows for seamless communication,
          document sharing and coordination of tasks among all parties involved.
          This transparency improves accountability and ensures everyone is on
          the same page regarding project milestones, budgets, and timelines.
          Developers can access real-time updates and monitor the project's
          progress, making timely adjustments when necessary. BIM's ability to
          provide accurate data and simulations enables developers to mitigate
          risks, optimize resources, and ensure the successful completion of
          projects within set parameters.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/solutions/bim-construction-project-management/image-5.png"
            alt="QA/QC monitoring and controlling"
            title="QA/QC monitoring and controlling"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Fig.4 QA/QC monitoring and controlling
          </Typography>
        </div>
        <Typography variant="h2" color="blue">
          Consultant's Perspective
        </Typography>
        <Typography variant="body1">
          For consultants, BIM technology has transformed how they contribute to
          construction projects. BIM offers consultants a collaborative platform
          where they can integrate their expertise and contribute to the design
          and planning process more effectively. By utilizing BIM authoring
          tools such as Revit, Archicad, Tekla, Building Designer, and Allplan,
          consultants can create accurate 3D models and simulations to visualize
          the proposed designs and identify potential conflicts or issues early
          on. This capability enables consultants to provide valuable insights
          and recommendations to optimize the building's performance, energy
          efficiency, and sustainability.
        </Typography>
        <Typography variant="body1">
          BIM technology also improves coordination and communication between
          consultants, reducing the likelihood of errors or discrepancies in the
          project documentation. Consultants can work concurrently on the same
          BIM model, accessing and updating information in real-time, ensuring
          all stakeholders have the most up-to-date data. This collaborative
          approach fosters efficient decision-making, minimizes design changes
          during construction, and improves overall project outcomes.
        </Typography>
        <Typography variant="body1">
          Additionally, BIM technology enables consultants to streamline their
          workflows and increase productivity. By automating repetitive tasks
          and utilizing BIM's data-driven approach, consultants can spend less
          time on manual drafting and more time on value-added activities such
          as analysis, problem-solving, and innovation. This shift in focus
          allows consultants to deliver higher-quality designs, meet project
          objectives more efficiently, and provide better value to their
          clients.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/bim-construction-project-management/image-6.png"
            alt="Laser scanner point cloud and BIM workflow"
            title="Laser scanner point cloud and BIM workflow"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Fig.5 Laser scanner point cloud and BIM workflow
          </Typography>
        </div>
        <Typography variant="h2" color="blue">
          Contractor's Perspective
        </Typography>
        <Typography variant="body1">
          BIM technology has significantly impacted the role of contractors on
          construction sites. It provides contractors with a comprehensive and
          coordinated virtual model of the project, enabling them to plan better
          and execute construction activities. Using BIM technology, contractors
          can simulate and optimize construction sequences, identify potential
          clashes or interferences, and plan logistics efficiently. This level
          of precision and foresight reduces the likelihood of rework, delays,
          and cost overruns during construction.
        </Typography>
        <Typography variant="body1">
          BIM software such as the SIGNAX suite enhances contractor and
          stakeholder collaboration. Contractors can share the BIM model in the
          cloud-specific work scope with subcontractors, enabling them to
          visualize the project's requirements and constraints accurately. This
          facilitates better coordination and communication, reducing conflicts
          and ensuring the smooth execution of construction tasks. Contractors
          can also leverage BIM's ability to generate accurate quantity
          take-offs, enabling them to estimate material requirements and costs
          more accurately, leading to optimized procurement and cost control.
        </Typography>
        <Typography variant="body1">
          Furthermore, BIM technology enables contractors to manage construction
          progress in real-time. By integrating BIM with project management ERP
          software and cloud-based CDE systems, contractors can track the
          progress of different construction activities, identify bottlenecks,
          and make data-driven decisions to optimize productivity. BIM's
          visualization AR/VR capabilities and 360-degree images also aid in
          communicating construction plans to workers, enhancing safety and
          reducing errors on-site.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/bim-construction-project-management/image-7.png"
            alt="360-degree aerial photo comparison"
            title="360-degree aerial photo comparison"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Fig 6. 360-degree aerial photo comparison
          </Typography>
        </div>
        <Typography variant="body1">
          As per each new workflow implementation or business process
          optimization, requirements must be fulfilled.
        </Typography>
        <Typography variant="body1">
          There are three critical prerequisites to maximizing the efficiency of
          BIM technology throughout all stages of construction project
          management.
        </Typography>
        <List className="pl-4">
          <li>
            To ensure the optimal effectiveness of BIM technology throughout the
            project lifecycle, initiating its continuous usage from its
            inception is crucial. If project phases or stakeholders' tasks are
            omitted from utilizing BIM technology, the value of BIM data for all
            project participants may be compromised.
          </li>
          <li>
            The second crucial requirement is standardization. We have to
            provide arrangements for standardized software implementation.
            Ensure that a process flow for model development, exchanging
            information, archiving, and updating data in real-time is
            established so that no critical information is lost or corrupted.
          </li>
          <li>
            The third necessity is proficient BIM and digital skills among all
            stakeholders. Each project role should receive appropriate training
            to efficiently execute tasks using relevant digital tools.
          </li>
        </List>
        <Typography variant="body1">
          Drawing from our experience in adopting and implementing digital
          technologies in the construction industry, engaging all stakeholders
          and establishing effective project management workflows can be
          challenging. However, once these fundamental digital requirements are
          successfully implemented, every participant will experience
          substantial value and enhanced work efficiency, benefiting each
          stakeholder involved in the project.
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default BimConstructionProjectManagement
